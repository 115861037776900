@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,200;9..40,300;9..40,400;9..40,600;9..40,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Expletus+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Proza+Libre&display=swap');

:root {
  /* Colors */
  --verde100: #BCE8C0;
  --verde200: #74C37A;
  --verde300: #63B069;
  --verde400: #539D58;
  --verde500: #428B48;
  --verde600: #327837;
  --verde700: #216526;
  --verde800: #115215;
  --verde900: #0b400f;
  --text-color: #ffffff;
  --dark-text-color: #08330b;
  --delete-color: #df372b;

  /*Scala di Grigi*/
  --extra-light-gray: #f8f9faff;
  --soft-light-gray: #f2f2f3;
  --light-gray: #ced4daff;
  --primary-gray: #adb5bdff;
  --soft-dark-gray: #89949e;
  --dark-gray: #6c757dff;
  --extra-dark-gray: #343a40ff;
}

/* Generale */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--verde900);
  background-image: linear-gradient(130deg, var(--verde600), var(--verde900));
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  color: var(--text-color);
  font-weight: 400;
}

#main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

a {
  color: var(--text-color);
  text-decoration: none;
}

p {
  margin: 10px 0px;
  font-size: 17px;
  line-height: 22px;
}

h1,
h2 {
  font-family: 'Proza Libre', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  font-weight: 600;
}

hr {
  border: none;
  border-top: 1px solid white;
}

img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.no-scroll {
  overflow: hidden;
}

.container {
  width: 100%;
  padding: 0 1rem;
  max-width: 1200px;
  margin: 0 auto
}

.container-page {
  width: 100%;
  padding: 0 1rem;
  max-width: 900px;
  margin: 0 auto
}

.section-auth .container {
  max-width: 700px;
}

.pointer {
  cursor: pointer
}

.btn {
  border: 0px;
  border-radius: 3px;
  padding: 10px 15px;
  text-transform: uppercase;
  background-color: var(--verde800);
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: 1px solid white
}

.btn:disabled {
  border-radius: 3px;
  text-transform: uppercase;
  background-color: var(--verde800);
  color: white;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  opacity: 0.5;
}

.btn.btn-danger {
  background-color: var(--delete-color);
}

.btn.btn-hero {
  margin-top: 30px;
  width: 100%;
  border: 1px solid white;
  position: relative;
  padding: 15px 25px;
  font-weight: 500;
  font-size: 14px;
}

.btn .btn-icon {
  position: absolute;
  top: 13px;
  right: 5px;
  font-size: 20px;
}

.shadow {
  box-shadow: 0px 0px 10px 0px #00000038;
}

.section-padding {
  padding: 20px 0px
}

.page-section {
  padding-top: 95px;
  padding-bottom: 40px;
}

.page-title {
  margin-bottom: 20px;
  font-weight: 600;
}

.message-is-error {
  text-decoration: underline;
  text-decoration-color: var(--delete-color);
}

.message-is-error.centered {
  text-align: center;
}

.contact svg {
  position: relative;
  margin-right: 10px;
  font-size: 20px;
  top: 3px
}

.tool-page .react-datepicker {
  font-size: 1rem;
}

.tool-page .react-datepicker__navigation {
  top: 10px
}

.tool-page .react-datepicker__portal .react-datepicker__day-name,
.tool-page .react-datepicker__portal .react-datepicker__day,
.tool-page .react-datepicker__portal .react-datepicker__time-name {
  width: 2.2rem;
  line-height: 2.2rem;
}

.tool-page .react-datepicker__navigation {
  border-radius: 3px;
  border: 1px solid gray;
  background-color: white;
}

.tool-page .react-datepicker__navigation-icon {
  margin: 0px
}

.tool-page .react-datepicker__navigation-icon--next::before {
  left: -4px
}

.tool-page .react-datepicker__navigation-icon--previous::before {
  right: -4px;
}

.tool-page .react-datepicker__navigation--previous {
  left: 10px
}

.tool-page .react-datepicker__navigation--next {
  right: 10px;
}

.tool-page .react-datepicker__header {
  padding: 11px 0px 0px 0px
}

/* Home */
.section-home {
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.section-home .btn {
  margin: 10px 0px 15px 0px
}

/* Loading spinner */

.loader {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: var(--verde500);
  background-image: linear-gradient(130deg, var(--verde600), var(--verde900));
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader.isLoading {
  opacity: 1;
  visibility: visible;
}

.loader.isLoaded {
  opacity: 0;
  visibility: hidden;
}

.loader.isError {
  opacity: 0;
  visibility: hidden;
}

@keyframes loader {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}

.loader-inner div {
  box-sizing: border-box !important
}

.loader-inner>div {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 25px;
  left: 25px;
  border-radius: 50%;
  border: 4px solid #000;
  border-color: #ffffff transparent #ffffff transparent;
  animation: loader 0.8695652173913042s linear infinite;
}

.loader-inner>div:nth-child(2) {
  border-color: transparent
}

.loader-inner>div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}

.loader-inner>div:nth-child(2) div:before,
.loader-inner>div:nth-child(2) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 4px;
  height: 4px;
  top: -4px;
  left: 19px;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 0 46px 0 0 #ffffff;
}

.loader-inner>div:nth-child(2) div:after {
  left: -4px;
  top: 19px;
  box-shadow: 46px 0 0 0 #ffffff;
}

.loader-external {
  width: 98px;
  height: 98px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.loader-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.98);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

.loader-inner div {
  box-sizing: content-box;
}


/* Navbar */
.main-nav {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 9999;
  transition: all 0.1s ease-in;
}

.main-nav.main-nav-color {
  background-color: #0000009c;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.nav-header {
  padding: 10px 0px
}

.nav-header {
  display: flex;
  justify-content: space-between;
}

.container-nav-link-desktop {
  display: none;
}

.nav-brand {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: 20px;
  white-space: nowrap;
  font-family: "Expletus Sans", cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nav-brand .logo {
  text-transform: uppercase;
  font-size: 17px;
}

.nav-brand .logo-span {
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  display: block;
  position: relative;
}

.nav-brand .logo-span svg {
  font-size: 18px;
  position: absolute;
  top: -2px;
  left: 0px;
}

.nav-sidebar .container {
  height: 100%;
}

.nav-sidebar .nav-links {
  text-align: center;
  width: 100%;
  max-height: 100%;
  overflow: scroll;
}

.nav-sidebar .nav-links .nav-link svg {
  position: absolute;
  top: 2px;
  right: -30px;
}

.nav-sidebar .nav-links .nav-link {
  position: relative;
  display: inline-block;
  margin: 0 auto
}

.nav-sidebar .nav-links a {
  padding: 14px 0px;
  display: block;
  font-size: 20px;
  font-weight: 400;
  border-bottom: 1px solid var(--verde900)
}

.nav-sidebar .nav-links a:last-child {
  border-bottom: 0px
}

.container-nav-link-sidebar {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px
}

.nav-toggler {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nav-toggler button {
  background: none;
  border: 0px;
  color: #efefef;
  font-size: 35px;
}

.nav-sidebar {
  color: var(--dark-text-color);
  position: fixed;
  width: 100%;
  min-height: 100vh;
  background-color: white;
  top: 0px;
  right: -100%;
  transition: all 0.3s ease-in-out;
  z-index: 999999;
  height: 100%;
}

.nav-sidebar.show-sidebar {
  right: 0px
}

.nav-sidebar a {
  color: var(--dark-text-color)
}

.nav-sidebar button {
  color: var(--dark-text-color)
}

/* Footer */
footer {
  padding: 10px 0px;
  text-align: center;
  color: #08330b;
  background-color: white;
  margin-top: auto
}

footer p {
  margin: 5px 0px
}

footer hr {
  border-color: #08330b
}

/* Hero Section */
.hero-section {
  position: relative;
  width: 100%;
  height: 100vh;
  max-height: 500px;
}

.hero-section h1 {
  font-size: 25px;
  font-weight: 600;
}

.hero-section .overlay-hero {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: #00000075;
}

.hero-section .overlay-hero .container {
  padding-top: 70px;
  height: 100%;
  display: flex;
  align-items: center;
}

/* INIZIO TOOL -------------------------------- */

.container-slider .slick-dots li.slick-active button:before {
  color: white
}

.container-slider .slick-dots li button:before {
  color: white
}

.btn-modal-calendario {
  position: fixed;
  bottom: 15px;
  left: 10px;
  background-color: black;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background-image: linear-gradient(230deg, #b41483, #010063);
  border: 3px solid white;
  transition: all 0.2s ease-in-out;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0px 0px 7px 0px black;
}

.btn-modal-calendario svg {
  font-size: 25px;
}

.btn-modal-calendario[calendaranimation='1'] {
  animation: calendarAnimation 1s 1;
}

.btn-modal-calendario[calendaranimation='1'] svg {
  animation: calendarAnimationSvg 1s 1;
}

@keyframes calendarAnimation {
  25% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes calendarAnimationSvg {
  25% {
    transform: rotate(20deg);
  }

  50% {
    transform: rotate(-20deg);
  }

  75% {
    transform: rotate(20deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.gotologin-calendario {
  background-color: white;
  color: black;
  padding: 10px 7px;
  border-radius: 3px;
  text-align: center;
  margin: 20px 0px
}

.btn-modal-calendario.show-btn-modal-calendario {
  visibility: visible;
  opacity: 1;
}

.btn-modal-calendario.show-btn-modal-calendario span {
  display: none;
}

.incipit-tool {
  font-size: 18px;
  background-color: var(--verde800);
  padding: 10px 20px;
  border-radius: 10px;
  line-height: 25px;
  border: 1px solid white;
  display: block;
}

.header-form {
  margin-bottom: 30px;
  margin-top: 30px
}

.container-date-btns {
  margin-top: 20px
}

.reset-tool {
  background-color: white;
  color: black;
  width: 45%;
  float: right;
  opacity: 0;
  visibility: hidden;
  font-size: 18px;
  border: 0px
}

.reset-tool:hover {
  background-color: #efefef;
}

.reset-tool.open-reset-tool {
  opacity: 1;
  visibility: visible;
}

.container-search-eventi-form {
  background-color: #c9eac9;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #00000082;
  padding: 20px;
}

.containerDatepicker input {
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid gray;
  cursor: pointer;
  font-size: 17px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.containerDatepicker .react-datepicker-wrapper:first-child {
  margin-right: 30px;
}

.react-datepicker__tab-loop {
  display: inline-block;
}

.search-eventi {
  width: 45%;
  font-size: 18px;
  border: 0px
}

.search-eventi:hover {
  background-color: #1c4f1c;
}

.cont-checkin-input,
.cont-checkout-input {
  display: inline-block;
  width: 100%;
}

.cont-checkin-input {
  margin-bottom: 10px
}

.cont-checkin-input span,
.cont-checkout-input span {
  margin-bottom: 5px;
  color: black;
  display: block;
  font-size: 17px;
}

.container-punti-giorni {
  display: flex;
  gap: 5px;
  background-color: #225e22;
  border-radius: 13px;
  transition: all 0.5s ease;
  flex-direction: column;
}

.container-punti-giorni.giorni-1 {
  grid-template-columns: 1fr;
}

.container-punti-giorni.giorni-2 {
  grid-template-columns: 1fr 1fr;
}

.container-punti-giorni.giorni-3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.container-punti-giorni.giorni-4 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.container-punti-giorni.giorni-5 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.container-punti-giorni.giorni-6 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.container-punti-giorni.giorni-7 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.container-punti-giorni.giorni-8 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.container-punti-giorni span {
  width: auto;
  height: auto;
  display: block;
  background-color: white;
  color: black;
  padding: 10px;
  text-align: center;
  border-radius: 3px;
  padding-bottom: 15px
}

.container-punti-giorni>span {
  box-shadow: 0px 0px 10px 0px #00000082;
}

.container-punti-giorni.giorni-1 span:last-child {
  border-radius: 10px;
}

.container-punti-giorni span:first-child {
  border-radius: 10px 10px 3px 3px;
}

.container-punti-giorni span:last-child {
  border-radius: 3px 3px 10px 10px;
}

.container-punti-giorni span p {
  margin-top: 0px;
  font-size: 17px;
}

.content-blocco-attivita {
  font-size: 17px;
}

.blocco-attivita {
  background-color: #225e22;
  color: white;
  font-size: 13px;
  padding: 10px 20px;
  border-radius: 5px;
  max-width: 400px;
  margin: 0 auto 10px auto;
  background-image: linear-gradient(45deg, #174017, transparent);
  position: relative;
  min-height: 82px;
  display: flex;
  align-items: center;
}

.blocco-attivita hr {
  margin: 7px 0px
}

.blocco-attivita>div {
  width: 100%;
}

.blocco-attivita p {
  margin-bottom: 0px;
  font-weight: 700;
}

.blocco-attivita.blocco-att-skeleton p {
  font-weight: 500;
}

.blocco-attivita.blocco-att-skeleton {
  background-color: var(--light-gray);
  background-image: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.blocco-attivita.blocco-attivita-intera-giornata {
  min-height: 174px;
}

.blocco-attivita hr {
  border: 0px;
  border-top: 1px solid white
}

.header-span-calendario small {
  text-transform: capitalize;
}

.header-span-calendario {
  line-height: 19px;
  margin-bottom: 10px;
}

.header-span-calendario p {
  margin-bottom: 3px;
  font-weight: 600;
}

.blocco-attivita:last-child {
  margin-bottom: 0px
}

.container-punti-giorni span.remove-attivita {
  padding: 0px;
  background-color: red;
  color: white;
  position: absolute;
  top: -6px;
  left: -6px;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  font-size: 19px;
}

.overlay-calendario {
  margin-bottom: 40px;
  position: fixed;
  top: 0px;
  left: -100%;
  width: 100%;
  z-index: 99999;
  height: 100vh;
  overflow: scroll;
  transition: all 0.3s ease-in-out;
}

.calendario {
  padding-bottom: 40px;
  padding-top: 16px;
  background-color: var(--verde700);
  min-height: 100vh;
}

.overlay-calendario.show-calendar {
  left: 0px;
}

.calendario .calendar-title {
  text-align: center;
  margin-bottom: 0px
}

.calendario .btn {
  display: block;
  margin: 20px auto;
  margin-bottom: 20px;
  padding: 10px 25px;
  border: 1px solid white
}

.calendario .nav-toggler {
  position: absolute;
  top: 7px;
  right: 7px;
}

.header-slider-attivita {
  text-transform: capitalize;
  margin: 0px -16px 5px -16px;
  font-size: 20px;
  border-top: 1px solid white;
  padding-top: 10px;
  text-align: center;
}

.header-slider-attivita p {
  margin: 0px;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 2px
}

.container-slider {
  padding: 0px;
}

.box-attivita {
  background: #fff;
  color: black;
  margin: 8px;
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #00000082;
}

.tool-page .box-attivita {
  cursor: grab
}

.tool-page .box-attivita:active {
  cursor: grabbing
}

.slick-prev {
  left: -15px !important
}

.slick-next {
  right: -15px !important
}

.container-skeleton {
  display: grid;
  margin-bottom: 9px;
  grid-template-columns: 1fr 1fr;
  gap: 0px
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.box-attivita.box-attivita-skeleton {
  background-color: #92a594;
  min-height: 400px;
  width: auto;
  box-shadow: none;

}

.container-slider .box-attivita.box-attivita-skeleton {
  display: none;
}

.container-slider .box-attivita.box-attivita-skeleton:nth-child(1),
.container-slider .box-attivita.box-attivita-skeleton:nth-child(2) {
  display: block;
}

.box-attivita.box-attivita-skeleton .titolo-attivita {
  height: 34px;
  border-radius: 3px;
}

.box-attivita.box-attivita-skeleton .date-attivita {
  border-radius: 3px;
  height: 20px;
  margin: 20px 0px
}

.box-attivita.box-attivita-skeleton .btns-attivita {
  height: 36px;
  border-radius: 3px;
}

.box-attivita.box-attivita-skeleton .btns-attivita.btns-attivita-last {
  margin-top: 14px
}

.container-img-singolo-loading {
  height: 180px;
  border-radius: 10px;
}

.container-descr-attivita-loading {
  margin-top: 20px;
  height: 200px;
  border-radius: 10px;
}

.box-attivita.box-attivita-skeleton .img-attivita,
.box-attivita.box-attivita-skeleton .date-attivita,
.box-attivita.box-attivita-skeleton .titolo-attivita,
.box-attivita.box-attivita-skeleton .btns-attivita,
.container-archivio-attivita .lista-cat-skeleton .btn-filtri,
.page-title-loading,
.container-img-singolo-loading,
.container-descr-attivita-loading {
  background: #eee;
  background: linear-gradient(90deg, var(--verde800) 8%, #17561a 18%, var(--verde800) 33%);
  background-size: 200% 200%;
  animation: 1s shine linear infinite;
  border: 0px
}

.row-elenco-attivita {
  margin-bottom: 40px
}

.img-attivita {
  height: 150px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px 10px 0px 0px;
  background-position: center;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: left;
  transition: all 0.3s ease-out;
}

.category-attivita {
  font-size: 14px;
  border-radius: 5px;
  background-color: #000000a6;
  color: white;
  padding: 3px 7px;
  text-align: right;
}

.info-attivita {
  padding: 10px;
}

.btns-attivita {
  margin-top: 15px
}

.titolo-attivita h3 {
  margin: 0px;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;

}

.date-attivita {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid lightgray;
  margin-top: 15px;
  padding-top: 10px;
  border-top: 1px solid lightgray
}

.durata-attivita {
  padding-bottom: 10px;
  border-bottom: 1px solid lightgray;
}

.date-attivita p,
.durata-attivita p {
  margin: 0px;
  font-size: 15px;
  color: #3f3f3f
}


.btns-attivita button {
  opacity: 1;
  width: 100%;
  margin-bottom: 15px;
  font-size: 16px;
  border: 0px
}

.btns-attivita button:last-child {
  margin-bottom: 0px;
  background-color: white;
  border: 1px solid var(--verde900);
  color: var(--verde900);
  font-size: 14px;
}

.icon-calendario,
.icon-durata {
  position: relative;
  top: 1px
}

.slick-track {
  margin-left: 0px !important
}

.remove-attivita {
  cursor: pointer;
}

.modal-periodo-attivita {
  position: absolute;
  background-color: white;
  display: flex;
  flex-direction: column;
  width: auto;
  left: -2px;
  right: -2px;
  bottom: 93px;
  margin: 0px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px #000000bd
}

.modal-periodo-attivita::after {
  content: '';
  display: block;
  position: absolute;
  background-color: white;
  width: 15px;
  height: 15px;
  transform: rotate(45deg);
  bottom: -7px;
  z-index: 1;
  left: 27px
}

.modal-periodo-attivita button {
  margin-top: 10px;
  border: 0px;
  padding: 15px 5px;
  text-transform: uppercase;
  border-radius: 5px;
  background-color: #efefef;
  cursor: pointer;
  position: relative;
  z-index: 5;
}

.modal-periodo-attivita>button:first-child {
  margin-top: 0px
}

/* FINE TOOL */

/* SINGOLA ATTIVITA' */

.sidebar-attivita {
  height: fit-content;
  flex-basis: 50%;
  margin-top: 30px;
}

.container-img-text {
  flex-basis: 100%;
}

.container-img-singolo {
  width: 100%;
  height: 180px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 10px;
  display: flex;
  justify-content: left;
  align-items: flex-start;
  border-radius: 10px;
  background-color: var(--verde900);
}

.container-dettagli-attivita {
  margin-top: 20px
}

.inner-container-dettagli-attivita {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.inner-container-dettagli-attivita span {
  background-color: var(--verde900);
  border-radius: 10px;
  color: white;
  padding: 10px 15px 10px 37px;
  display: inline-block;
  position: relative;
  font-size: 16px;
  width: 100%;
}

.inner-container-dettagli-attivita span.span-info-aggiuntiva {
  padding: 10px 15px
}

.inner-container-dettagli-attivita span svg {
  position: absolute;
  top: 12px;
  left: 10px
}

.inner-container-dettagli-attivita span.span-prenotazione svg {
  font-size: 20px;
  top: 10px
}

.box-sidebar {
  background-color: var(--soft-light-gray);
  border-radius: 10px;
  color: black;
  overflow: hidden;
  margin-top: 30px
}

.box-sidebar p {
  margin-top: 0px
}

.box-sidebar:first-child {
  margin-top: 0px
}

.box-sidebar>div {
  padding: 15px
}

.box-sidebar h4 {
  font-size: 22px;
  padding: 10px 15px;
  background-color: var(--verde100);
  border-bottom: 1px solid gray;
  text-align: center;
}

.container-download a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 20px;
  border-radius: 5px;
}

.container-download p {
  text-align: center;
}

.container-download>div>div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px
}

.container-download a:first-child {
  margin-top: 0px
}

.container-download a img {
  margin-left: 20px;
  width: 32px;
  height: auto;
}

.container-contatti>div>div {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid lightgray
}

.container-contatti p {
  text-align: center;
}

.container-contatti button {
  width: 100%;
}

.container-contatti>div>div:first-child {
  margin-top: 0px;
  padding-top: 0px;
  border-top: 0px
}

.container-contatti ul {
  list-style: none;
}

.container-contatti ul li {
  font-size: 18px;
  margin-top: 15px;
  text-align: center;
  display: flex;
  flex-direction: column-reverse;
  font-weight: 500;
}

.container-contatti ul li:first-child {
  margin-top: 0px
}

.container-contatti ul li a {
  background-color: var(--verde800);
  display: block;
  border-radius: 3px;
  margin-top: 5px;
}

.container-contatti ul li a,
.container-contatti .btn {
  padding: 13px 15px;
  font-weight: 500;
  font-size: 20px;
  border-radius: 5px;
}

.container-img-singolo .category-attivita {
  font-size: 17px;
  padding: 5px 10px
}

.container-scheda-tecnica ul {
  list-style: none;
}

.container-scheda-tecnica ul li {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid lightgray
}

.container-scheda-tecnica ul li:last-child {
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-bottom: 0px
}

.return-arrow {
  background-color: white;
  color: #216526;
  display: inline-block;
  padding: 5px 12px;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 15px;
  margin-bottom: 15px;
  font-weight: 600;
}

.return-arrow svg {
  position: relative;
  top: 2px
}

.page-title-loading {
  height: 30px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.container-mappa,
.container-google-maps,
.container-galleria {
  margin-top: 40px
}

.container-google-maps iframe {
  width: 100%;
  border-radius: 10px;
  height: 450px;
  background-color: #dcdfe1;
}

.ReactGridGallery_tile {
  background: none !important;
}

.subtitolo-attivita {
  font-size: 25px;
  line-height: normal;
  font-weight: 700;
}

/* FINE SINGOLA ATTIVITA */

.container-descr-attivita {
  margin-top: 20px
}

.container-descr-attivita ul {
  padding-left: 18px
}

.archivio-attivita {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px
}

.archivio-attivita .box-attivita {
  margin: 0px;
  height: fit-content;
}

.container-archivio-attivita .lista-categorie {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.container-archivio-attivita .btn-filtri {
  text-transform: none;
  padding: 7px 15px;
  border-radius: 10px;
  border: 1px solid var(--verde800)
}

.container-archivio-attivita .btn-filtri.active {
  border: 1px solid white
}

.container-archivio-attivita .lista-cat-skeleton button.btn-filtri {
  background-color: var(--verde800);
  padding: 15px 43px;
  box-shadow: none;
  border-color: var(--verde800)
}

.container-archivio-attivita .box-attivita.box-attivita-skeleton {
  min-height: 350px;
  margin: 0px
}

.container-archivio-attivita .container-skeleton {
  gap: 20px;
  margin-bottom: 20px
}

.container-archivio-attivita .box-attivita.box-attivita-skeleton .btns-attivita.btns-attivita-last {
  display: none;
}

/* Autenticazione */

.section-auth label,
.section-auth input {
  display: block;
}

.section-auth input:not(.btn) {
  width: 100%;
  padding: 10px;
  margin: 5px 0px 15px 0px;
  border: 0px;
  border-radius: 3px;
}

.section-auth input[type="submit"] {
  width: auto;
  display: inline-block;
  margin-right: 10px;
}

.container-forgot-password {
  border-top: 1px solid white;
  padding-top: 15px
}

.section-auth .span-error {
  font-size: 14px;
}

.section-auth input[type="checkbox"] {
  display: inline-block;
  width: auto;
}

.container-form {
  padding-bottom: 10px
}

.section-auth .btn {
  margin-bottom: 20px;
}

.section-auth .btn.btn-logout {
  margin-right: 15px;
}

.container-btns-auth {
  display: flex;
}

.container-btns-auth button {
  width: 100%;
  background-color: var(--verde900);
  border: 1px solid var(--verde900)
}

.container-btns-auth button.active {
  background-color: var(--verde500);
  border-color: white
}

.container-btns-auth button:first-child {
  border-radius: 3px 0px 0px 3px;
}

.container-btns-auth button:last-child {
  border-radius: 0px 3px 3px 0px;
}

.header-profilo {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 25px;
}

.section-auth .calendario {
  position: static;
  display: block;
  overflow: visible;
  height: 100%;
  background: none;
  padding: 0px
}

.section-auth .calendario .container {
  padding: 0px
}

.section-auth .calendario .container .calendar-title {
  margin-bottom: 10px;
  margin-top: 20px;
  border-top: 1px solid white;
  padding-top: 10px
}

@media(min-width: 350px) {
  .btn.btn-hero {
    font-size: 16px;
  }
}

@media(min-width: 375px) {
  .btn.btn-hero {
    font-size: 17px;
  }

  .btn .btn-icon {
    top: 14px
  }

  .tool-page .react-datepicker__portal .react-datepicker__day-name,
  .tool-page .react-datepicker__portal .react-datepicker__day,
  .tool-page .react-datepicker__portal .react-datepicker__time-name {
    width: 2.7rem;
    line-height: 2.7rem;
  }

  .inner-container-dettagli-attivita span {
    font-size: 17px;
  }
}


@media(min-width: 425px) {
  .btn.btn-hero {
    width: auto;
    padding: 15px 40px 15px 15px
  }

  .btn .btn-icon {
    right: 10px;
  }

  .container-img-singolo {
    height: 220px;
  }

  .tool-page .react-datepicker__portal .react-datepicker__day-name,
  .tool-page .react-datepicker__portal .react-datepicker__day,
  .tool-page .react-datepicker__portal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
  }

  .inner-container-dettagli-attivita span {
    width: auto;
  }
}

@media(min-width: 500px) {
  .sidebar-attivita {
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr 1fr;
  }

  .box-sidebar {
    margin-top: 0px
  }
}

@media(min-width: 650px) {

  .archivio-attivita,
  .container-skeleton {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .container-slider .box-attivita.box-attivita-skeleton:nth-child(3) {
    display: block;
  }

  .container-img-singolo {
    height: 270px;
  }
}

@media(min-width: 780px) {

  h1 {
    font-size: 40px;
    line-height: 43px;
  }

  h2 {
    font-size: 32px;
    line-height: 37px;
  }

  .container,
  .container-page {
    padding: 0 1.5rem
  }

  .page-section {
    padding-top: 125px;
  }

  .hero-section {
    max-height: 600px;
  }

  .nav-sidebar,
  .nav-header .nav-toggler {
    display: none;
  }

  .nav-header {
    padding: 15px 0px
  }

  .container-nav-link-desktop {
    display: flex;
    align-items: center;
  }

  .container-nav-link-desktop ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
  }

  .container-nav-link-desktop ul a {
    margin-left: 13px;
    white-space: nowrap;
    padding: 5px 0px;
    font-size: 14px;
    font-weight: 600;
  }

  .container-nav-link-desktop ul a.active,
  .container-nav-link-desktop ul a:hover {
    border-bottom: 1px solid white
  }

  .container-nav-link-desktop ul a:first-child {
    margin-left: 0px;
  }

  .container-nav-link-desktop ul a svg {
    display: none;
  }

  .main-nav {
    background-color: #0000009c;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .header-slider-attivita {
    margin: 0px -25px 5px -25px
  }

  .btns-attivita {
    display: flex;
    justify-content: space-between;
  }

  .btns-attivita button {
    width: auto;
    margin-bottom: 0px;
    font-size: 14px;
    padding: 10px 15px
  }

  .modal-periodo-attivita {
    bottom: 53px
  }

  .page-title-loading {
    height: 43px;
  }

  .container-img-singolo-loading {
    height: 270px;
  }

  .container-attivita {
    display: flex;
    justify-content: space-between;
    gap: 20px
  }

  .sidebar-attivita {
    margin-top: 0px;
    display: block;
  }

  .box-sidebar {
    margin-top: 30px
  }
}

@media(min-width: 840px) {

  .btns-attivita button {
    padding: 10px 20px
  }

  .container-nav-link-desktop ul a {
    font-size: 15px;
  }

  .container-search-eventi-form {
    display: flex;
    justify-content: space-between;
    padding: 0px;
    position: relative;
    border-radius: 10px 15px 15px 10px;
  }

  .containerDatepicker {
    display: flex;
    padding: 20px 10px;
  }

  .container-date-btns {
    margin-top: 0px
  }

  .container-date-btns button {
    height: 100%;
    box-shadow: none;
  }

  .reset-tool {
    position: absolute;
    z-index: 9;
    left: 0px;
    opacity: 1;
    visibility: visible;
    width: auto;
    padding: 0px 15px
  }

  .reset-tool.open-reset-tool {
    left: -87%;
  }

  .search-eventi {
    position: relative;
    z-index: 99;
    width: auto;
    border-radius: 0px 10px 10px 0px;
    padding: 0px 20px;
  }

  .container-date-btns {
    position: relative;
  }

  .cont-checkin-input,
  .cont-checkout-input {
    margin-bottom: 0px;
    display: flex;
    align-items: center;
  }

  .cont-checkin-input {
    margin-right: 10px;
  }

  .cont-checkin-input span,
  .cont-checkout-input span {
    display: inline-block;
    margin-right: 10px;
  }

  .header-slider-attivita {
    margin: 0px auto 5px auto
  }

  .slick-slider.container-box-attivita {
    margin: 0px -7px
  }

  .btn-modal-calendario {
    right: 10px;
    left: auto
  }

  .calendario .nav-toggler {
    top: 9px
  }

  .overlay-calendario {
    background-color: #000000b0;
    left: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .overlay-calendario.show-calendar {
    opacity: 1;
    visibility: visible;
  }

  .overlay-calendario .calendario {
    width: 50%;
    height: 80vh;
    overflow: scroll;
    position: relative;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px black;
  }
}

@media(min-width: 940px) {

  .container-img-singolo {
    height: 350px;
  }

  .container-slider .box-attivita.box-attivita-skeleton:nth-child(4) {
    display: block;
  }

  .hero-section h1 {
    font-size: 40px;
    line-height: 43px;
  }

  h2 {
    font-size: 37px;
    line-height: 40px;
  }


  .container-nav-link-desktop ul a {
    margin-left: 20px;
    font-size: 16px;
  }

  .hero-section {
    max-height: 700px;
  }

  .section-home {
    display: flex;
    gap: 20px;
    justify-content: space-between;
  }

  .section-home .inner-section-home {
    flex-basis: 50%;
    display: flex;
    align-items: center;
  }

  .section-home:nth-child(2n) {
    flex-direction: row-reverse;
  }

  .section-home {
    padding: 20px 0px;
    margin: 20px 0px
  }

  .archivio-attivita,
  .container-skeleton {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .containerDatepicker {
    padding: 20px
  }

  .btns-attivita button {
    padding: 10px
  }

  .search-eventi {
    padding: 0px 40px
  }

  .reset-tool.open-reset-tool {
    left: -63%
  }

  .btn-modal-calendario.show-btn-modal-calendario {
    width: auto;
    padding: 0px 15px;
    border-radius: 20px;
  }

  .btn-modal-calendario.show-btn-modal-calendario span {
    display: block;
    margin-right: 10px;
  }

  .container-punti-giorni span .header-span-calendario p {
    font-size: 21px;
    line-height: normal;
  }

  .header-span-calendario {
    font-size: 20px;
  }

  .container-img-singolo-loading {
    height: 350px;
  }

  .content-blocco-attivita {
    font-size: 18px;
  }

  .container-download a {
    width: auto;
  }

  .box-sidebar h4,
  .container-download p,
  .container-contatti p {
    text-align: left;
  }

  .container-contatti button {
    width: auto;
  }

  .container-contatti ul li {
    display: block;
    text-align: left;
  }

  .container-contatti ul li a {
    margin-top: 0px;
    margin-right: 10px;
    width: auto;
    display: inline-block;
  }

}

@media(min-width: 1092px) {
  .btns-attivita button {
    padding: 10px 20px
  }

  .header-slider-attivita {
    text-align: left;
  }

  .header-slider-attivita p {
    line-height: normal;
    font-size: 27px;
  }

  .header-slider-attivita small {
    font-size: 22px;
  }
}

@media(min-width: 1200px) {

  .container-img-singolo,
  .container-img-singolo-loading {
    margin: 0 auto;
  }
}

@media(min-width: 1300px) {
  .btn-modal-calendario.show-btn-modal-calendario {
    right: 60px;
  }
}

@media(min-width: 1500px) {
  .btn-modal-calendario.show-btn-modal-calendario {
    right: 100px;
  }
}

@media(min-width: 1700px) {
  .btn-modal-calendario.show-btn-modal-calendario {
    right: 170px;
  }
}

@media(min-width: 1900px) {
  .btn-modal-calendario.show-btn-modal-calendario {
    right: 220px;
  }
}

@media(min-width: 2000px) {
  .btn-modal-calendario.show-btn-modal-calendario {
    right: 300px;
  }
}

@media(min-width: 2200px) {
  .btn-modal-calendario.show-btn-modal-calendario {
    right: 450px;
  }
}

@media(max-width: 779px) {
  .sidebar-attivita {
    position: static !important;
  }
}